import { ChainId, ERC20Token } from '@pancakeswap/sdk'

export const coreTokens = {
  oex: new ERC20Token(
    ChainId.CORE,
    '0xFd843e2eB0E5F7e652fB864477D57510a469b332',
    18,
    'OEX',
    'OpenEX Network Token',
    'https://openex.network',
  ),
  wcore: new ERC20Token(
    ChainId.CORE,
    '0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f',
    18,
    'WCORE',
    'Wrapped Core',
    'https://coredao.org/',
  ),
  usdt: new ERC20Token(
    ChainId.CORE,
    '0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1',
    6,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
  usdc: new ERC20Token(
    ChainId.CORE,
    '0xa4151B2B3e269645181dCcF2D426cE75fcbDeca9',
    6,
    'USDC',
    'USD Coin',
    'https://www.centre.io/usdc',
  ),
  come: new ERC20Token(
    ChainId.CORE,
    '0xB4A452e975C006619559E30069804F873475f111',
    18,
    'COME',
    'Call of Memes Token',
    'https://come.yachts',
  ),
  tcgc: new ERC20Token(
    ChainId.CORE,
    '0xEe214d6b5Ae526Ea06EA01A181eDFa87663AbB22',
    18,
    'TCGC',
    'TCGame Coin',
    'https://tcgame.app',
  ),
}
